/* Tables */

.table {
    margin-bottom: 0;
    thead {
        th {
            border-top: 0;
            border-bottom-width: 1px;
            font-weight: $font-weight-medium;
            vertical-align: top;
            font-size: 0.9rem;
            line-height: 1.2;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            i{
                margin-left: 0.325rem;
            }
            img {
                //height: 250px;
                //border-radius: 100%;
            }
            .badge {
                margin-bottom: 0;
            }
        }
    }
    td{
        font-weight: 400;
    }
    td {

        vertical-align: middle;
        font-size: 0.9rem;
        line-height: 1.1;
        // white-space: initial;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        img {
            //height: 250px;
            //border-radius: 100%;
        }
        .badge {
            margin-bottom: 0;
        }
    }
    &.table-borderless{
        border: none;
        tr,td,th{
            border: none;
        }
    }
}
.table-wrap-text{
    th,
    td {
        white-space: normal !important;
    }
}

.table-wrap-word {
    white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap;          /* Chrome & Safari */ 
    white-space: -pre-wrap;                 /* Opera 4-6 */
    white-space: -o-pre-wrap;               /* Opera 7 */
    white-space: pre-wrap;                  /* CSS3 */
    word-wrap: break-word;                  /* Internet Explorer 5.5+ */
    word-break: break-all;
    white-space: normal;
}

.tablegame{
    background-color: #fff;
}
.thgmae {
    background-color: #F1F1F1;
    border-radius: 20px;
}
.table-left-corner{
    border-top-left-radius: 20px !important;
    border: 2px;
}
.table-right-corner{
    border-top-right-radius: 20px !important;
    border: 2px;
}