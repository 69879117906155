/* Forms */

.form-group {
  margin-bottom: 1.5rem;
}

.input-group-append,
.input-group-prepend {
  color: $input-placeholder-color;
  width: auto;
  border: none;
  .input-group-text {
    border-color: $border-color;
    padding: 0.575rem 0.75rem;
    color: $input-placeholder-color;
  }
  button {
    padding-top: .3rem;
    padding-bottom: .3rem;
  }
}

.custom-control {
  .custom-control-label {
    line-height: 1.6;
    margin-bottom: 0;
  }
}

.form-control {
  border: 1px solid $border-color;
  font-size: $input-font-size;
  border-radius: 0.25em;
  font-weight: 450;
}

.delete-icon{
  color: $red-color;
}
.edit-icon{
  color: $black;
}
/*.select-gm{
        height: calc(1.9em + 0.75rem + 1px);
        padding: .375rem .75rem;
        border: 1px solid #ced4da;
        border-radius: 0.25em;
        width: 100%;
        font-family: "Poppins", sans-serif;
        color: #6C757D;
    }
*/
select {
  &.form-control {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAnUlEQVR42r2KywmEUAxFA+JCBCOIiIIfdARd6FtYgCW8Eiw5paSETOY54yC69pxFkpsLj7DYBW9zXKyOeTUy03SpTDiTflYYeRSVhlNlwJFcztBvL/nYUXtUWuxoT/tNz8ZWXIlKpauUWJG7ubGwU5icc1E5M9mxFwb+pCbhRFT+zdTAmbiOKJKvFNdwJcSAAlEpRLjHR4888hGe5Q1r5EKfhDTIzgAAAABJRU5ErkJggg==') no-repeat right #ffffff;
    //background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ffffff;
    -webkit-appearance: none;
    background-position: 98%;
    font-family: $type1;
    //padding: .4375rem .75rem;
    //border: 1px solid #ced4da;
    border: 1px solid $form-border-color;
    border-radius: 0.25em;
    outline: 1px solid $form-border-color;
    color: $input-placeholder-color;
    &:focus {
      outline: 1px solid $form-border-color;
    }
    @each $color,
    $value in $theme-colors {
      &.border-#{$color} {
        outline: 1px solid $form-border-color;
        &:focus {
          outline: 1px solid $form-border-color;
        }
      }
    }
  }
}

.form-group {
  .error-msg{
    font-size: 0.8rem;
  }
  label {
    font-size: $default-font-size;
    line-height: 1;
    vertical-align: top;
    margin-bottom: .5rem;
    font-weight: 450;
  }
  &.has-danger {
    .form-control {
      border-color: theme-color(danger);
    }
  }
  .file-upload-default {
    visibility: hidden;
    position: absolute;
  }
  .file-upload-info {
    background: transparent;
  }
}
.custom-file {
  .visibility-hidden {
    visibility: hidden;
  }
  .custom-file-label {
    background: $input-bg;;
    outline: 1px solid $form-border-color;
    height: calc(2.25rem + 2px);
    font-weight: normal;
    font-size: 0.875rem;
    padding: 0.625rem 0.6875rem;
    border-radius: 0.25em;
    &:after {
      background-color: theme-color(primary);
      height: auto;
      @extend .btn-primary;
    }
  }
}

/*.date-picker-container {
  position: relative;
}

.date-picker-icon {
  position: absolute;
  top: 50%;
  right: 25px; 
  transform: translateY(-50%);
  pointer-events: none;
  cursor: pointer;
}
.date-picker-wrapper {
  display: flex;
  align-items: center;
}


.date-field {
  padding-right: 2.5rem;  Ensure there's space for the icon 
}*/
.date-field {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAKVJREFUSEtjZKAxYMRj/n+oHD41ICV41dHdAgcGBob5DAwMCmSG3AMGBoZEBgaGAzD96D7YD5QEWUIJAFmiiMsCYsMdlwMw9KP7gG4WUBJEIL1wh+PyAc0tIJT2B38cwHyAHumk8hGRAfUzqQYQUk/QAlIje/Dkg9E4IJiTaZbRqFFcg+oCR1zFNaguqKegTiBY4ZCa7gmqJzesCRqMK4iI1kisQgD15TQZBANIuAAAAABJRU5ErkJggg==') no-repeat right #ffffff;
    -webkit-appearance: none;
    background-position: 98%;
}
