/* Miscellanoeous */
body,
html {
    //overflow-x: hidden;
    padding-right: 0;
    // resets padding right added by Bootstrap modal
    font-family: $type1;
}

body {
    font-family: $type1;
    background-color: $content-bg;
}


*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
    overflow: auto;
}

.container-scroller {
    overflow: hidden;
}

.react-datepicker__day{
    padding: 0px 0 !important;//default 0.5rem 0
}

/*.container {//container-fluid page-body-wrapper
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-top: 20px;
    background-color: #fff0;
    box-shadow: none;
    }
*/
.page-body-wrapper {
    min-height: calc(100vh - #{$navbar-height});
    padding-top: $page-body-top-padding;
    //background-color: $page-body-background;
    @include display-flex();
    @include flex-direction(row);
    padding-left: 0;
    padding-right: 0;

    &.full-page-wrapper {
        min-height: 100vh;
        padding-top: 0;
        .main-panel {
            width: 100%;
            transition: none;
            .content-wrapper {
                padding: 0;
                > div {
                    height: 100%;
                }
            }
        }
    }
}
.table-view-title{
    margin-bottom: 20px;
}
.table-view-title h3{
    font-size: 1.7rem;
    font-weight: 600;
    color: $card-title-color;
}

    
.main-panel {
    transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
    //width: calc(100% - #{$sidebar-width-lg});
    width: 100%;
    min-height: calc(100vh - #{$navbar-height});
    @include display-flex();
    @include flex-direction(column);
    @media (max-width: 991px) {
        margin-left: 0;
        width: 100%;
    }
    &.main-panel-only {
        transition: none;
    }
}

.content-wrapper {
    background: $content-bg;
    //padding: 2.75rem 2.25rem;
    padding: 1.75rem 1.5rem;
    width: 100%;
    @include flex-grow(1);
}





pre {
    background: color(white);
    padding: 15px;
    font-size: 14px;
}

code {
    padding: 5px;
    color: theme-color(danger);
    font-weight: $font-weight-light;
    font-size: $default-font-size;
    border-radius: 4px;
}

.page-header {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    margin: 0 0 1.5rem 0;
    .breadcrumb {
        border: 0;
        margin-bottom: 0;
    }
}
.page-title {
    color: $black;
    font-size: 1.125rem;
    margin-bottom: 0;
    .page-title-icon {
        display: inline-block;
        width: 36px;
        height: 36px;
        border-radius: 4px;
        text-align: center;
        box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
        i {
            font-size: .9375rem;
            line-height: 36px;
        }
    }
}

.big-header{
    color: #173e67;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: .18px;
    line-height: 30px;
}

.small-header{
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #173e67d9!important;
}
