.notification-icon {
    display: inline-flex;
    align-items: center;
    position: relative;
}

.notification-dot {
    width: 8px;
    height: 8px;
    background-color: green;
    border-radius: 50%;
    position: absolute;
    top: -2px;
    right: -4px;
}

.notification-text {
    font-size: 8pt; /* Adjust the font size as needed */
    line-height: 1; /* Adjust the line height as needed */
}

.notification-text.bold {
    font-weight: bold;
}

.notification-item {
  display: flex;
  align-items: center;
  height: 30px; /* Adjust the height as needed */
}

.btn-custom {
  padding: 5px;
  font-size: 8pt;
}
